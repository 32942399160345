export const stringToRGB = (string = "") => {
    const COLORS = [
        "rgb(45, 124, 161)",
        "rgb(94, 149, 170)",
        "rgb(98, 173, 202)",
        "rgb(59, 118, 162)",
        "rgb(80, 145, 205)",
        "rgb(128, 184, 238)",
        "rgb(66, 157, 204)",
        "rgb(137, 181, 224)",
        "rgb(122, 184, 220)",
        "rgb(116, 89, 42)",
        "rgb(141, 94, 56)",
        "rgb(183, 87, 27)",
        "rgb(217, 177, 4)",
        "rgb(192, 156, 70)",
        "rgb(181, 172, 148)",
        "rgb(169, 189, 188)",
        "rgb(200, 178, 189)",
        "rgb(174, 179, 202)",
    ];
    const hash = string
        .split("")
        .map((char) => char.charCodeAt(0))
        .reduce((a, b) => a + b, 0);
    return COLORS[hash % COLORS.length];
};
