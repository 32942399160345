import * as React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { Layout } from "../components/common";

// Utilities
import kebabCase from "lodash/kebabCase";
import { stringToRGB } from "../utils/tag";

const Tags = ({ data, location }) => (
    <Layout>
        <div className="container">
            <div className="content" style={{ textAlign: `center` }}>
                <h1>Browse all tags</h1>
                <div className="tag-wrapper">
                    {data.allGhostTag.edges.map((edge) => (
                        <Link
                            key={edge.node.slug}
                            to={`/tag/${kebabCase(edge.node.slug)}/`}
                        >
                            <span
                                className="tag"
                                style={{
                                    backgroundColor: stringToRGB(edge.node.slug),
                                }}
                            >
                                {edge.node.name}: {edge.node.postCount}
                            </span>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    </Layout>
);

Tags.propTypes = {
    data: PropTypes.shape({
        allGhostTag: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Tags;

export const pageQuery = graphql`
    query ($limit: Int!, $skip: Int!) {
        allGhostTag(
            sort: { order: DESC, fields: [name] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    slug
                    name
                    postCount
                }
            }
        }
    }
`;
